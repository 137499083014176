import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Stack, styled, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteUser, loginAdmin } from "../../apiservices";
import { toast } from "react-toastify";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({ data, editUser, getData, handleCheck }) {
  const [state, setState] = React.useState({
    password: "",
    isDelete: false
  });
  const columns = [
    { field: "fname", headerName: "First Name", width: 250 },
    { field: "lname", headerName: "Last Name", width: 250 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "contact", headerName: "Contact", width: 150 },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => edit(params.row)}>
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
        </>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editUser(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    setState((prev) => ({
      ...prev,
      isDelete: true,
      id: params.id,
      name: params.fname + " " + params.lname,
    }));
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        // checkboxSelection
      />
      <CustomDialogue
      open={state.isDelete}
      handleClose={() => { }}
      header={
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typo>Deletion</Typo>
        </Stack>
      }
      content={
        <>
          <Stack sx={{ padding: 1 }}>
            <Stack sx={{ mt: 2 }}>
              <Typography>Please enter password if you really want to delete <strong>{`"${state.name}"`}</strong>.</Typography>
              <input onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
              }} type="password" />
            </Stack>
          </Stack>
          <div style={{ float: "right" }}>
            <Btn
              sx={{ height: '30px', mt: '10px', mr: '5px', padding: 0, background: "red", color: "white" }}
              variant="contained"
              size="small"
              onClick={async () => {
                let userData = JSON.parse(localStorage.getItem("user"));
                let credentials = {
                  username: userData.username,
                  password: state.password,
                };
                let resUser = await loginAdmin(credentials);
                if (resUser.success) {
                  let res = await deleteUser(state.id);
                  if (res.success) {
                    toast.success("User deleted successfully");
                    getData();
                    setState((prev) => ({
                      ...prev,
                      isDelete: false,
                    }));
                  }
                }
                else {
                  toast.error("Wrong password...!")
                }
              }}
            >Delete</Btn>
            <Btn
              sx={{ height: '30px', mt: '10px', mr: '5px', padding: 0 }}
              variant="contained"
              color="error"
              size="small"
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  isDelete: false,
                }));
              }}
            >Close</Btn>
          </div>
        </>
      }
    />
    </div>
  );
}
