import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#52b335",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SimpleTable({ dataTable }) {
  const [state, setState] = React.useState({
    tableData: [],
    itemsArr: dataTable,
  });
  // React.useEffect(() => {
  //   getData();
  // }, []);
  // const getData = async () => {
  //   let obj = {
  //     company_code: dataTable.company_code,
  //     modelName: dataTable.modelName,
  //   };
  //   // let res = await getProductDetailsZRT(obj);
  //   // if (res) {
  //   //   let arr = [];
  //   //   res.quantity.map((item) => {
  //   //     arr.push(item.items[0]);
  //   //   });
  //     // setState((prev) => ({ ...prev, tableData: [], itemsArr: [] }));
  //   // }
  // };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "600px" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Remarks</StyledTableCell>
            <StyledTableCell>Party</StyledTableCell>
            <StyledTableCell align="left">In</StyledTableCell>
            <StyledTableCell align="left">Out</StyledTableCell>
            <StyledTableCell align="left">Closing</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.itemsArr.map((row, key) => (
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                {moment(row.date).format("DD-MM-YYYY")}
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                {row.remarks}
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                {row.party}
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                {row.in}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row.out}
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                {row.closing}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
