import React from "react";
import Sidebar from "../../sidebar";
import Dashboard from "./dashboard";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Sale from "../sale";
import Payment from "../payment";
import Client from "../client";
import Product from "../product";
import Provider from "../Provider";
import BankComp from "../bank";
import CountryComp from "../country";
import CategoryComp from "../categories";
import ClientSheet from "../client/clientSheet";
import StockSheet from "../stock/stockSheet";
import Purchase from "../purchase";
import SupplierPayment from "../supplier_payment";
import Users from "../users";
import TransactionComp from "../transaction";
import ProfitComp from "../profit";
import CashComp from "../cash";
import CashWithdrawal from "../cashWithdrawal";
import ExpenseHeadsComp from "../expense";
import BankTransfer from "../bank_transfer";

function Dashboad() {
  const { selectedCom } = useSelector((state) => state.sidebar);
  return (
    <Box sx={{ width: "100%", display: "flex", alignItems: "stretch" }}>
      <Box sx={{ flex: "0 0 auto" }}>
        <Sidebar />
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          overflowX: "auto",
          height: "100vh",
        }}>
        {selectedCom === "dashboard" && <Dashboard />}
        {selectedCom === "sale" && <Sale />}
        {selectedCom === "payment" && <Payment />}
        {selectedCom === "supplier_payment" && <SupplierPayment />}
        {selectedCom === "client" && <Client />}
        {selectedCom === "stock" && <Purchase />}
        {selectedCom === "products" && <Product />}
        {selectedCom === "provider" && <Provider />}
        {selectedCom === "bank" && <BankComp />}
        {selectedCom === "bank_transfer" && <BankTransfer />}
        {selectedCom === "country" && <CountryComp />}
        {selectedCom === "category" && <CategoryComp />}
        {selectedCom === "stockSheet" && <StockSheet />}
        {selectedCom === "clientSheet" && <ClientSheet />}
        {selectedCom === "transaction" && <TransactionComp />}
        {selectedCom === "profit" && <ProfitComp />}
        {selectedCom === "cash" && <CashComp />}
        {selectedCom === "cashWithdrawal" && <CashWithdrawal />}
        {selectedCom === "expense" && <ExpenseHeadsComp />}
        {selectedCom === "users" && <Users />}
      </Box>
    </Box>
  );
}

export default Dashboad;
